import { PopupButton } from 'react-calendly';
import React from 'react';
import css from './CalendlyBooking.module.scss';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isPlatformBrowser from '../../../util/isPlatformBrowser.util';

export default function CalendlyBooking({ schedulePageUrl, isPanel = true }) {
  const containerClass = isPanel ? css.container : css.containerNonPanel;
  const text = (
    <>
      <FontAwesomeIcon className={css.phoneIcon} icon={'fa-solid fa-phone-volume'} />
      <FormattedMessage id="ListingPage.calendlyCtaLink" />
    </>
  );
  const handleClick = () => {
    window.gtag('event', 'click_vibe_check', {
      event_category: 'engagement',
      event_label: 'click_vibe_check',
      event_name: 'click_vibe_check',
      value: 1, // Optional
    });
  };
  return (
    isPlatformBrowser() && (
      <div className={containerClass}>
        <p className={css.message}>
          <FormattedMessage id="ListingPage.calendlyCtaMessage" />
        </p>
        <div onClick={handleClick} >
        <PopupButton
          className={css.popupButton}
          url={schedulePageUrl}
          rootElement={document.getElementById('root')}
          text={text}
        />
        </div>
      </div>
    )
  );
}
